import React, { useEffect, useState, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction';

import './main.scss'


export default function Calendar(props) {

  const { locationId, domain, domainAppointments, isOwner, height } = props
  const availabilitiesUrl = `${domain}/locations/${locationId}/availabilities`;
  // const eventsUrl = `${domainAppointments}/locations/${locationId}/appointments/private`;
  // const eventsUrl = `${domainAppointments}/api/v1/locations/${locationId}/appointments`
  const eventsUrl = `${domainAppointments}/api/v1/appointments/${locationId}/provider_appointments`
  // const programItemsUrl = `${domain}/locations/${locationId}/provider_program_items`;
  const courtListUrl = `${domain}/locations/${locationId}/courts`
  const editAppointment = `/locations/${locationId}/appointments/edit`;
  const screenHeight = height ? height : window.screen.availHeight

  const [courts, setCourts] = useState([])
  const [businessHours, setBusinessHours] = useState({})
  const calendarRef = useRef(null)

  const initFetchs = [
    courtListUrl, 
    availabilitiesUrl
  ]

  const getCalendarApi = () => {
    const calendar = calendarRef.current.getApi()
    const start = moment(calendar.getDate()).startOf('isoWeek').toDate();
    const end = moment(calendar.getDate()).endOf('isoWeek').toDate();
    return { start, end }
  }

  useEffect(() => {
    if (props.date) {
      calendarRef.current.getApi().gotoDate(props.date)
    }
    const { start, end } = getCalendarApi()
    initFetchs[1] = `${availabilitiesUrl}?start=${start}&end=${end}`
    Promise.all(initFetchs.map(url =>
      fetch(url)
      .then(result => result.json())
    ))
    .then(data => {
      const [courtList, businessHourList] = data
      setCourts(courtList.courts)
      const bHours = businessHourList.map(avail => {
        avail.startTime = moment(avail.start).format('HH:mm');
        avail.endTime = moment(avail.end).format('HH:mm');
        avail.daysOfWeek = [avail.dow % 7]
        avail.constraint = {
          resourceIds: courtList.courts
        }
        return avail;
      })
      setBusinessHours(bHours)
      calendarRef.current.getApi().addEventSource(eventsUrl)
    })
  }, [])

  const editEvent = async info => {
    const { start, end, id } = info.event
    const { notes } = info.event.extendedProps
    console.log(info)
    const date = moment(start.toUTCString()).utc().format("YYYY-MM-DD")
    const start_time = moment(start.toUTCString()).utc().format("HH:mm")
    const end_time = moment(end.toUTCString()).utc().format("HH:mm")
    const courtIds = info.event._def.resourceIds
    // const newCourtIds = info.newResource ? [info.newResource.id] : info.event._def.resourceIds
    const body = JSON.stringify({
      update_appt: { 
        "appt_id": id,
        "day_of_week": Number(moment(start).isoWeekday()),
        "start_time": start_time,
        "end_time": end_time,
        "new_date": date,
        "court_ids": courtIds,
        "notes": notes,
      }
    })

    try {
      const result = await fetch(editAppointment, {
        method: 'POST',
        body,
        headers: {
          'Content-Type':'application/json'
        }
      })
      $('#calendar').fullCalendar('unselect');
      $('#calendar').fullCalendar('refetchEvents'); 
    } catch (e) {
      console.log(e)
    }
  }

  const newAppointment = info => {
    const courtId = info.resource.id
    const date = moment(info.start.toUTCString()).utc().format("YYYY-MM-DD")
    const start = moment(info.start.toUTCString()).utc().format("HH:mm")
    const end = moment(info.end.toUTCString()).utc().format("HH:mm")

    $.get('/locations/'+locationId + '/available_courts?date='+date+'&start_time=' + start + '&end_time=' + end).then(function(data) {
      
      $("#end_date").hide()
      $('#appointment_recurring').change(function() {
        if ($(this).prop('checked') == false) {
          $("#end_date").hide()
        } else {
          $("#end_date").show()
        }
      })

      $('#appointment_email').focus(function() {
        var user_name = $('#appointment_name').val()
        var user = $('.ui.search.appointment').search('get result', user_name)
        this.value = (user.title != user_name) ? '' : (user.email ? user.email : '')
        user_name = ''
      })

      $("#pro_names").hide()
      $("input[type=radio][name=pro_tier]").change(function(){
        if($(this).attr("value") == "none"){
          $("#pro_names").hide()
        } else {
          $("#pro_names").show()
        }
      })

      var courts = data.courts;
      var courtCheckBoxHtml = '<label for="appointment_court_ids">Select Courts</label>'
      for(var i=0;i<courts.length; i++) {
        var court = courts[i];
        var selectedCourt = courtId == court.id
        var disabledCourt = courtCheckBoxHtml+'<div class="ui disabled checkbox"><input type="checkbox" disabled="disabled" value="'+court.id+'" name="appointment[court_ids][]" id="appointment_court_ids_'+court.id+'"><label>Court '+court.name+'</label></div><br>';
        var enabledCourt = courtCheckBoxHtml+'<div class="ui checkbox"><input type="checkbox" value="'+court.id+'" name="appointment[court_ids][]" id="appointment_court_ids_'+court.id+'" '+(selectedCourt ? "checked" : "")+'><label>Court '+court.name+'</label></div><br>';
        var htmlText = court.available ? enabledCourt : disabledCourt
        courtCheckBoxHtml = htmlText
      }

      if(courts.length > 0) {
        $("#new_appointment_submit_btn").removeAttr("disabled");
      } else {
        courtCheckBoxHtml = '<p>No Available Court</p>'
      }

      $("#appointment_court_ids").html(courtCheckBoxHtml);

    })

    

    // $.get('/locations/' + locationId + '/available_pros?date='+date+'&start_time=' + start + '&end_time=' + end).then(function(data) {
      // console.log(data)
      // $("#pro_availabilities").each(function(e) {
      //   if($('div', this).length > 0) {
      //     $("#pro_availabilities").html('')
      //   }
      // })
      // var availabilities = data.availabilities
      // var prosCheckBoxHtml = '<label for="pro_availabilities">Pros</label>'
      // for (var i=0; i<availabilities.length; i++) {
      //   var availability = availabilities[i]
      //   var pro = availability.pro
      //   var disabledPro = prosCheckBoxHtml+'<div class="ui disabled checkbox"><input type="checkbox" disabled="disabled" value="'+pro.id+'" name="appointment[pro_id]" id="'+availability.fee+'"><label>' +pro.name + '</label></div><br>';
      //   var enabledPro = prosCheckBoxHtml+'<div class="ui checkbox"><input type="checkbox" value="'+pro.id+'" name="appointment[pro_id]" id="'+availability.fee+'"><label>'+pro.name +'</label></div><br>';
        
      //   var htmlText = availability.available ? enabledPro : disabledPro
      //   prosCheckBoxHtml = htmlText
      // }

      // if(availabilities.length > 0) {
      //   $("#pro_availabilities").html(prosCheckBoxHtml);
      //   $("input[type='checkbox']").change(function() {
      //     if ($(this).prop('checked') == false) {
      //       var start_hour = $("input[name='appointment[start_time_str]']").val()
      //       var end_hour = $("input[name='appointment[end_time_str]']").val()
      //       var number_of_courts = $("#appointment_number_of_courts").val() ? $("#appointment_number_of_courts").val() : 1
      //       getPrice(locationId, 0, start_hour, end_hour, date, number_of_courts)
      //     } else {
      //       $("input[type='checkbox']").not(this).prop('checked', false);  
      //       var start_hour = $("input[name='appointment[start_time_str]']").val()
      //       var end_hour = $("input[name='appointment[end_time_str]']").val()
      //       var number_of_courts = $("#appointment_number_of_courts").val() ? $("#appointment_number_of_courts").val() : 1
      //       getPrice(locationId, this.id, start_hour, end_hour, date, number_of_courts)
      //     }
      //   })
      // }
    // })

    
    $("#appointment_date_str").val(date);
    $("#appointment_start_time_str").val(start);
    $("#appointment_end_time_str").val(end);
    $('#new-reservation-modal').modal('show')
  }

  const handleDateSet = dateInfo => {
    $('#dailyNotesText').hide()
    $('#dailyNotesTextLoading').show()

    var date = parseDate()
    var notesUrl = '/locations/' + locationId + '/location_notes?date=' + date
    $.get(notesUrl).then(function(data) {
      $('#dailyNotesText').val(data.note)
      $('#dailyNotesText').show()
      $('#dailyNotesTextLoading').hide()
    })
  }

  const fillTransactionData = (transaction, remainingBalance) =>{
    if(transaction.paid == 'unpaid'){
      return `<div class="event">
        <div class="content">
          <div class="summary">
            $${parseFloat(transaction.amount / 100).toFixed(2)} added to balance
            <div class="date">
              on ${moment(transaction.created_at).format('LL')}
            </div>
          </div>
        </div>
      </div>`
    }

    return `<div class="event">
      <div class="content">
        <div class="summary">
          $${parseFloat(transaction.amount / 100).toFixed(2)} paid with ${transaction.payment_provider}
          <div class="date">
            on ${moment(transaction.created_at).format('LL')}
          </div>
        </div>
        <div class="meta">
          <a class="like">
            Remaining Balance: $${remainingBalance.toFixed(2)}
          </a>
        </div>
      </div>
    </div>`
  }

  const eventClick = data => {
    // reset all the fields and loading them 
    $("#appt_name").val('').prop("disabled", true)
    $("#userProfile").prop("disabled", true)
    $("#userProfile").text('Profile')
    $("#appt_email_label").text('Email')
    $("#appt_email").val('').prop("disabled", true)
    $("#appt_phone_label").text('Phone')
    $("#appt_phone").val('').prop("disabled", true)
    $("#appt_notes").val('').prop("disabled", true)
    $("#appt_date").val('').prop("disabled", true)
    $("#appt_start_time").val('').prop("disabled", true)
    $("#appt_end_time").val('').prop("disabled", true)
    // $("#appt_recurring").val('').prop("disabled", true)
    $("#appt_pro").val('').prop("disabled", true)
    // $("#actionsApptId").val('').prop("disabled", true)
    // $("#appt_is_paid").val('').prop("disabled", true)
    // $("#appt_id_paid_div").val('').prop("disabled", true)
    $("#appt_payment_methods").hide()
    $("#cardPresentTransactionIdPayForAppt").val('').prop("disabled", true)
    $("#payForApptEmail").val('').prop("disabled", true)
    $("#payForAppointmentForm").val('').prop("disabled", true)
    $("#payForApptTransctionTotal").val('').prop("disabled", true)
    $('#apptActionsCardOnFile').prop('disabled',true)
    $('#apptActionsAcctBalance').prop('disabled',true)
    $('#apptActionsMarkAsPaid').prop('disabled',false)
    // disable buttons 
    $("#edit_apptmt_form").prop("disabled", true)
    $("#checkin_apptmt_form").prop("disabled", true)
    $("#cancel_apptmt_form").prop("disabled", true)

    $("#actionsProgramId").hide()
    $("#actionsProgramId").html('')
    $("#actionsProgramId").attr('programId','')

    const { event } = data
    if(!event.is_available) {
      var apptmtID = event.id

      var editApptUrl = `/locations/${locationId}/appointments/update?appt_id=${apptmtID}`
      var checkinApptUrl = `/locations/${locationId}/facility_checkin?appt_id=${apptmtID}`

      var appointmentDetailsUrl = `/appointments/${apptmtID}`

      // var cancelApptmtUrl = `/locations/${locationId}/appointments/${apptmtID}/cancel`
      // $("#cancel_apptmt_form").on('click', function(){
      //   var confirmation = confirm("Are you sure you would like to cancel this appointment?")
      //   if(confirmation){
      //     $.ajax({
      //       type: "POST",
      //       url: cancelApptmtUrl
      //     })
      //   }
      // })
      $.get(appointmentDetailsUrl).then(function(data) {
        // console.log(data)
        $("#appt_name").val(data.user.name)
        if(data.user.item_type == 'user'){
          $("#userProfile").prop("disabled", false).attr("href","/locations/" + locationId + "/settings?tab=client-profile&client=" + data.user.id)
        } else if(data.user.item_type == 'program'){
          $("#userProfile").prop("disabled", false).attr("href","/locations/" + locationId + "/programs/" + data.user.id + "/registrations")
          $("#actionsProgramId").html(`program id: ${data.user.id}`)
          $("#actionsProgramId").attr('programId',data.user.id)
          $("#userProfile").text('Registrations')
          $("#actionsProgramId").show()
        }
        $("#appt_email").val(data.user.email)
        $("#appt_phone").val(data.user.phone)
        $("#appt_notes").val(data.appointment.notes)
        const date = moment(new Date(data.appointment.date).toUTCString()).utc().format("YYYY-MM-DD")
        const start = moment(new Date(data.appointment.start_time).toUTCString()).utc().format("HH:mm")
        const end = moment(new Date(data.appointment.end_time).toUTCString()).utc().format("HH:mm")
        $("#appt_date").val(date)
        $("#appt_start_time").val(start)
        $("#appt_end_time").val(end)
        const isRecurring = data.appointment.recurring_schedule_id != null
        $("#appt_recurring").html('Not recurring')
        if (isRecurring) {
          $("#appt_recurring").html('Recurring')
        }
        const has_pro = data.appointment.pro_user_id != null
        if (has_pro){
          $("#appt_pro").val(data.pro.name)
        } else {
          $("#appt_pro").val('No pros linked to this appointment')
        }
        $("#appointmentActionsCheckOptionInput").prop("disabled", false)

        // var cancelApptmtUrl = `/locations/${locationId}/appointments/${data.appointment.id}/cancel`
        // $("#cancel_apptmt_form").on('click', function(){
        //   var confirmation = confirm("Are you sure you would like to cancel this appointment?")
        //   if(confirmation){
        //     $.ajax({
        //       type: "POST",
        //       url: cancelApptmtUrl
        //     })
        //   }
        // })

        $("#actionsApptId").html(`appointment id: ${data.appointment.id}`)
        $("#actionsApptId").attr('apptId',data.appointment.id)
        $("#actionsCreatedAt").html(`created at: ${moment(data.appointment.created_at).format('YYYY-MM-DD, hh:mm A')}`)
        $("#appt_is_paid").html('unpaid')
        $("#appt_id_paid_div").attr('class','ui right ribbon red big label')
        $("#appt_payment_methods").show()
        $('#apptPaidTransactions').hide()
        $("#cardPresentTransactionIdPayForAppt").prop("disabled", false).val("none")
        $("#payForApptEmail").prop("disabled", false).val(data.user.email)
        $("#payForAppointmentForm").prop("disabled", false).attr('action','/locations/' + locationId + '/appointments/' + data.appointment.id + '/pay_for_appointment')
        $('#appt_payment_duedate_month').html('Choose Month')
        $('#appt_payment_duedate_day').html('Choose Day')
        $('#appt_payment_duedate_year').html('Choose Year')

        const hasTransaction = data.transaction.id != 'no transaction'
        const paymentAmount = parseFloat(data.transaction.amount) / 100
        let dueAmount = paymentAmount

        data.child_transactions.forEach(t => dueAmount -= t.amount / 100)

        if(hasTransaction){
          $('#actionsTransactionId').prop('transactionId',data.transaction.id)
          const isPaid = data.transaction.paid == 'paid' || data.transaction.paid == 'manual'

          let feedText = ''
          let remainingAmount = paymentAmount
          if (data.child_transactions.length > 0){
            data.child_transactions.forEach(t => {
              remainingAmount -= t.amount / 100
              feedText += fillTransactionData(t, remainingAmount)
            })
          } else {
            feedText += fillTransactionData(data.transaction, data.transaction.amount / 100)
          }

          $('#apptPaidTransactionsFeed').html(feedText)
          $('#apptPaidTransactions').show()
          $('#apptPaidTransactions').attr('class','eight wide column')

          // $('#appt_payment_duedate').hide()
          $('#appt_payment_duedate_save_confirm').hide()
          $('#appt_payment_duedate').prop('date',moment(data.transaction.createdAt).format('YYYY-MM-DD'))

          let dueDate = data.transaction.created_at

          if(data.transaction.due_date)
            dueDate = data.transaction.due_date

          $('#appt_payment_duedate').prop('date',moment(dueDate).format('YYYY-MM-DD'))
          $('#appt_payment_duedate_month').html(moment(dueDate).format('MMM'))
          $('#appt_payment_duedate_day').html(moment(dueDate).format('DD'))
          $('#appt_payment_duedate_year').html(moment(dueDate).format('YYYY'))

          if(isPaid){
            $("#appt_id_paid_div").attr('class','ui right ribbon green big label')
            $("#appt_is_paid").html('paid: $' + paymentAmount.toFixed(2) + ", " + data.transaction.payment_provider)
            
            $("#appt_payment_methods").hide()
            $('#apptPaidTransactions').attr('class','sixteen wide column')
          } else {
            $("#appt_is_paid").html('accounts receivable: $' + dueAmount.toFixed(2))
            $("#appt_id_paid_div").attr('class','ui right ribbon red big label')
            $("#cardPresentTransactionIdPayForAppt").val(data.transaction.id)
            $("#payForApptTransctionTotal").prop("disabled",false).val(dueAmount.toFixed(2))
            $("#payForApptTransctionTotal").prop("value",dueAmount.toFixed(2))
          }
        } else {
          let priceUrl = '/api/v1/availabilities/' + locationId + '/open_courts/' + date + '/get_court_price'
          let priceUrlParams =  '?start_time=' + start + '&end_time=' + end
          $.get(priceUrl + priceUrlParams).then(function(priceData){
            baseRate = parseFloat(priceData.price) / 100
            $('#payForApptTransctionTotal').val(baseRate.toFixed(2))
          })
          $("#payForApptTransctionTotal").prop("disabled", false).val(paymentAmount.toFixed(2))
        }

        var reservationCategoryData = '<option value="none">None</option>'
        $.each(data.category.location_categories, function(key,value){
          reservationCategoryData = reservationCategoryData + '<option value="' + value.name + '"' + (value.name == data.category.name ? ' selected' : '') + '>' + value.name + '</option>'
        })
        $("#appointmentReservationCategory").html(reservationCategoryData)
        $("#edit_apptmt_form").prop("disabled", false)
        $("#checkin_apptmt_form").prop("disabled", false)
        $("#cancel_apptmt_form").prop("disabled", false)

        let clientPaymentUrl = `/locations/${locationId}/get_payment_methods?email=${encodeURIComponent(data.user.email)}`
        $.get(clientPaymentUrl).then(function(paymentData){
          var status = paymentData.status
          $('#apptActionsCardOnFile').prop('disabled',true)
          $('#apptActionsAcctBalance').prop('disabled',true)
          $('#apptActionsUserCredits').prop('disabled',true)

          $('#appointmentActionsCardOnFileText').text('Card on File')
          $('#appointmentActionsUserCreditsText').text(`User Credits`)

          console.log(paymentData)

          // handle credits
          if(status == 200 || status == 201){
            let creditBalance = parseFloat(paymentData.credit_balance) / 100
            if(creditBalance >= paymentAmount){
              $('#appointmentActionsUserCreditsText').text(`User Credits: $${creditBalance.toFixed(2)}`)
              $('#apptActionsUserCredits').prop('disabled',false)
            } else {
              $('#appointmentActionsUserCreditsText').text(`User Credits: $0.00 (insufficient)`)
            }
          }

          if(status == 200){
            $('#apptActionsCardOnFile').prop('disabled',false)
            $('#apptActionsAcctBalance').prop('disabled',false)
            $('#appointmentActionsCardOnFileText').text('Card on File: ' + paymentData.payment_info[0].card_number)
          } else if(status == 201) {
            $('#apptActionsCardOnFile').prop('disabled',true)
            $('#apptActionsAcctBalance').prop('disabled',false)
          } else {
            if($('#apptActionsCardOnFile').prop('checked') || $('#apptActionsAcctBalance').prop('checked')){
              $('#apptActionsCardOnFile').prop('checked',false)
              $('#apptActionsAcctBalance').prop('checked',false)
              $('#appointmentActionsCashOption').prop('checked',true)
            }
          }
        })
      })
      $('#update_actions_modal').modal('show');
      $("#edit_apptmt_form").off('click')
      $("#edit_apptmt_form").on('click', function() {
        $.get(editApptUrl).then(function(newData) {
          $('#update_appt_name').val(newData.user.name)
          $('#update_appt_notes').val(newData.notes)
          $('#update_user_id').val(newData.user.id)
          $('#user_id').val(newData.user.id)
          $('#appt_id').val(apptmtID)
          var courts = newData.courts
          var courtCheckBoxHtml = '<label for="appointment_court_ids">Courts</label>'
          for(var i=0;i<courts.length; i++) {
            var court = courts[i];
            courtCheckBoxHtml += '<div class="ui checkbox"><input type="checkbox" value="'+court.id+'" name="update_appt[court_ids][]" id="update_appt_court_ids_'+court.id+'"'+ (court.checked  ? 'checked' : '') + ' onClick="updateApptButtonStatus()"><label>Court '+court.name+'</label></div><br>';
          }

          $("#edit_fake_input").val(newData.user.name)
          if (newData.is_not_fake) {
            $("#edit_fake").hide()
            $("#edit_fake").parent().removeClass("action")
          } else {
            $("#edit_fake").show()
            $("#edit_fake").parent().addClass("action")
            $("#edit_fake").on("click", function() {
              $('#user_name').val(newData.user.name)
              $('#user_email').val(newData.user.email)
              $('#user_phone').val(newData.user.phone)
              $('#updated_user_location').val(locationId)
              $('#updated_user_id').val(newData.user.id)
              $('#update_appointment_modal').modal('toggle')
              $('#update_fake_user_modal').modal('show');
            })
          }
          $("#update_court_ids").html(courtCheckBoxHtml);
        })

        $('#update_appointment_modal').modal('show')
        $('#appointment_actions_modal').modal('toggle');
      })
      $("#checkin_apptmt_form").on('click', function(){
        $.ajax({
          type: "POST",
          url: checkinApptUrl
        })
      })
      $("#appointment_actions_modal").attr('appointment_id', apptmtID)
      $('#appointment_actions_modal').modal('show');
    } else {
      const date = moment(info.start.toUTCString()).utc().format("YYYY-MM-DD")
      const start = moment(info.start.toUTCString()).utc().format("HH:mm")
      const end = moment(info.end.toUTCString()).utc().format("HH:mm")

      $("#new_appointment_submit_btn").attr("disabled", "disabled");
      $("#appointment_court_ids").html('<p>Loading Available Courts...</p>');
      $("#price").html("<%= @has_location_read_write ? '' : ('Total: ' + number_to_currency(@location.min_price)) %>")
      // $("#owner_price").val(data.fee)
      $("#end_date").hide()
      $.get('/locations/'+locationId + '/available_courts?date='+date+'&start_time=' + start + '&end_time=' + end).then(function(data) {
        $('.ui.search').search({ source: data.names });

        $('#appointment_recurring').change(function() {
          if ($(this).prop('checked') == false) {
            $("#end_date").hide()
          } else {
            $("#end_date").show()
          }
        })

        $('#appointment_email').focus(function() {
          var user_name = $('#appointment_name').val()
          user = $('.ui.search').search('get result', user_name)
          this.value = (user.title != user_name) ? '' : (user.email ? user.email : '')
        })

        var courts = data.courts;
        var courtCheckBoxHtml = '<label for="appointment_court_ids">Select Courts</label>'
        for(var i=0;i<courts.length; i++) {
          var court = courts[i];
          var disabledCourt = courtCheckBoxHtml+'<div class="ui disabled checkbox"><input type="checkbox" disabled="disabled" value="'+court.id+'" name="appointment[court_ids][]" id="appointment_court_ids_'+court.id+'"><label>Court '+court.name+'</label></div><br>';
          var enabledCourt = courtCheckBoxHtml+'<div class="ui checkbox"><input type="checkbox" value="'+court.id+'" name="appointment[court_ids][]" id="appointment_court_ids_'+court.id+'"><label>Court '+court.name+'</label></div><br>';
          var htmlText = court.available ? enabledCourt : disabledCourt
          courtCheckBoxHtml = htmlText
        }

        if(courts.length > 0) {
          $("#new_appointment_submit_btn").removeAttr("disabled");
        } else {
          courtCheckBoxHtml = '<p>No Available Court</p>'
        }

        $("#appointment_court_ids").html(courtCheckBoxHtml);

      })

      $("input[name='appointment[start_time_str]']").on("input", function() {
        var end_hour = $("input[name='appointment[end_time_str]']").val()
        $.get('/locations/' + locationId + '/pro_fee/0/' + this.value + '/' + end_hour + '/' + date).then(function(data) {
          $("#price").html("<%= @has_location_read_write ? 'Free' : ('Total: ' + data.fee) %>")
          // $("#owner_price").val(data.fee)
        })
      });

      $("input[name='appointment[end_time_str]']").on("input", function() {
        var start_hour = $("input[name='appointment[start_time_str]']").val()
        $.get('/locations/' + locationId + '/pro_fee/0/' + start_hour + '/' + this.value + '/' + date).then(function(data) {
          $("#price").html("<%= @has_location_read_write ? 'Free' : ('Total: ' + data.fee) %>")
          // $("#owner_price").val(data.fee)
        })
      });
    }
  }

  let minTime = "00:00:00"

  if(businessHours && businessHours.length > 0) {
    const times = businessHours.filter(b => b.dow != null)
                    .map(b => moment(b.start).startOf('hour'))
                    // normalize all dates to 1/1/1970, still dynamic times
                    .map(b => b.set('year', 1970).set('month', 1).set('date', 1))
    // find time with earliest start time 
    minTime = moment.min(times).format("HH:mm:SS")
  }

  return (
    <FullCalendar 
      timeZone={'UTC'}
      height={screenHeight}
      contentHeight={screenHeight}
      schedulerLicenseKey="GPL-My-Project-Is-Open-Source" 
      defaultView="resourceTimeGridDay" 
      plugins={[ resourceTimeGridPlugin, interactionPlugin ]}
      eventLimit={true}
      selectable={true}
      navLinks={false}
      editable={isOwner}
      allDaySlot={false}
      resources={courts}
      displayEventTime={false}
      selectHelper={false}
      datesRender={handleDateSet}
      selectConstraint={"businessHours"}
      select={newAppointment}
      header={{
        left:'title',
        center:'long',
        right: 'changeView, prev,next, today',
      }}
      titleFormat={{
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      }}
      eventDrop={editEvent}
      eventResize={editEvent}
      minTime={minTime}
      businessHours={businessHours}
      ref={calendarRef}
      eventClick={eventClick}
      customButtons={{
        changeView: {
          text: "Pro Appointments",
          click: () => {
            window.location.href = `${domain}/locations/${locationId}/facility_week_view`
          }
        },
      }}
    />
  )
}
